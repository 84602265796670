<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
       <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>选择分类：</label>
                  <el-select v-model="params.categoryId" placeholder="请选择" clearable>
                     <el-option v-for="item in this.categoryOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                  <el-button class="m-left-10" type="primary" @click="openCategory">分类管理</el-button>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>搜索标题：</label>
                  <el-input v-model="params.title" placeholder="搜索标题"></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>搜索内容：</label>
                  <el-input v-model="params.content" placeholder="搜索内容"></el-input>
               </el-row>
               <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
               <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
            </el-row>
         </el-row>
       <el-row class="table-box">
         <el-row type="flex" justify="space-between">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button @click="createVisible = true" class="bg-gradient" type="primary">创建快捷回复</el-button>
               </el-row>
            </el-row>
         <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :stripe="true"
                border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="title" label="标题" min-width="70"></el-table-column>
               <el-table-column prop="content" label="快捷回复内容" min-width="70"></el-table-column>
               <el-table-column prop="categoryName" label="所属分类" min-width="70"></el-table-column>
               <el-table-column prop="createTime" label="创建时间"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button @click="handleEdit(scope.row)" type="text">
                        <el-link type="primary">编辑</el-link>
                     </el-button>
                     <el-button @click="handleDel(scope.row)" type="text">
                        <el-link type="danger">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </div>
    <el-dialog title="分类管理" width="550px" :visible.sync="categoryVisible">
         <el-button @click="categoryAddVisible = true" class="f-right m-bottom-15">新建分类</el-button>
         <el-table :data="categoryList" size="mini">
            <el-table-column label="序号" width="60">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="name" label="分类名称"></el-table-column>
            <el-table-column prop="quantity" label="所含数量"></el-table-column>
            <el-table-column label="操作">
               <template slot-scope="scope">
                  <el-button type="text">
                     <el-link type="primary" @click="handleEditCategory(scope.row)">编辑</el-link>
                  </el-button>
                  <el-button @click="handleDelCategory(scope.row.id)" type="text">
                     <el-link type="danger">删除</el-link>
                  </el-button>
               </template>
            </el-table-column>
         </el-table>
         <span slot="footer">
            <el-button @click="categoryVisible = false">取消</el-button>
         </span>
      </el-dialog>
    <el-dialog title="添加分类" width="450px" :visible.sync="categoryAddVisible" :close="closeAddCategory">
       <el-form ref="categoryForm" :model="categoryForm">
         <el-form-item label="分类名称" prop="name" required>
           <el-input v-model="categoryForm.name" class="width-280" clearable></el-input>
         </el-form-item>
       </el-form>
       <span slot="footer">
         <el-button @click="closeAddCategory">取消</el-button>
         <el-button @click="handleSaveCategory" type="primary">确定</el-button>
       </span>
     </el-dialog>
    <el-dialog title="创建快捷回复" width="550px" :visible.sync="createVisible" :close="handleClose">
         <el-form class="create-popup" ref="ruleForm" :model="ruleForm">
            <el-form-item label="所属分类" prop="categoryId" required>
               <el-select v-model="ruleForm.categoryId" class="width-280" placeholder="请选择" clearable>
                 <el-option v-for="item in this.categoryOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="标题" prop="title" required>
               <el-input v-model="ruleForm.title" class="width-280" placeholder="标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="content" required>
               <el-input v-model="ruleForm.content" class="width-280" type="textarea" clearable></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">确定</el-button>
         </span>
      </el-dialog>
  </div>
</template>
<script>
import { getPage, getSelect, getCategoryPage, removeCategory,
  saveCategory, saveFastReply, delFastReply } from "@/api/data/wechat/reply";
import { mapState } from 'vuex'
export default {
  name: 'liveCode',
  data() {
    return {
      crumbs: new Map([
        ['数据中台', ''], ['企业微信', ''], ['快捷回复', '']
      ]),
      tableData: [],
      loading: false,
      total: 0,
      limit: 0,
      page: 1,
      params: {
        categoryId: '',
        title: '',
        content: ''
      },
      categoryVisible: false,
      createVisible: false,
      ruleForm: {
        title: '',
        content: '',
        categoryId: '',
      },
      categoryOpt: [],
      categoryList: [],
      categoryTotal: 0,
      categoryPage: 1,
      categoryAddVisible: false,
      categoryForm: { name: '', id: '' }
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getReplyList()
    this.getReplyCategory()
  },
  methods: {
    // 获取回复列表
    getReplyList() {
      const query = `?page=${ this.page }&limit=${ this.limit }`,
            params = { ...this.params, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.total = total
        this.tableData = records
      })
    },
    // 获取分类下拉列表
    getReplyCategory() {
      const params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId };
      getSelect(params).then(({ success, records }) => {
        if (!success) return
        this.categoryOpt = records.map(i => ({ id: i.id, name: i.name }))
      })
    },
    // 查询回复列表
    handleQuery(bool) {
      this.page = 1
      if (bool) return this.getReplyList()
      for (const k in this.params) this.params[k] = ''
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getReplyList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getReplyList()
    },
    // 处理编辑回复
    handleEdit(row) {
      this.createVisible = true
      Object.assign(this.ruleForm, { id: row.id })
      for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k])
    },
    // 处理保存回复
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        const params = { ...this.ruleForm, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
        saveFastReply(params).then(({ success }) => {
          if (!success) return
          this.createVisible = false
          this.getReplyList()
          this.$message({ message: '保存回复成功！', type: 'success' })
        })
      })
    },
    // 关闭回复会话框
    handleClose() {
      this.createVisible = false
      delete this.ruleForm.id
      for (const k in this.ruleForm) this.ruleForm[k] = ''
    },
    // 删除回复
    handleDel({ id }) {
      this.$confirm('确定移除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delFastReply({ id }).then(({ success }) => {
          if (!success) return
          this.getReplyList()
          this.$message({ message: '删除回复成功！', type: 'success' })
        })
      })
    },
    // 打开分类会话框
    openCategory() {
      this.categoryVisible = true
      this.categoryList.length === 0 && this.getReplyCategoryPage()
    },
    // 获取分类分页列表
    getReplyCategoryPage() {
      const query = `?limit=7&page=${ this.categoryPage }`,
            params = { hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId };
      getCategoryPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.categoryTotal = total
        this.categoryList = records
      })
    },
    // 处理删除分类
    handleDelCategory(id) {
      this.$confirm('确定移除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeCategory({ id }).then(({ success }) => {
          if (!success) return
          this.getReplyCategoryPage()
          this.$message({ message: '删除分类成功！', type: 'success' })
        })
      })
    },
    // 处理编辑分类
    handleEditCategory({ id, name }) {
      this.categoryAddVisible = true;
      this.categoryForm.id = id
      this.categoryForm.name = name
    },
    // 处理保存分类
    handleSaveCategory() {
      this.$refs.categoryForm.validate(valid => {
        if (!valid) return
        const params = { name: this.categoryForm.name, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
        this.categoryForm.id && (params.id = this.categoryForm.id)
        saveCategory(params).then(({ success }) => {
          if (!success) return
          this.categoryAddVisible = false
          this.getReplyCategory()
          this.getReplyCategoryPage()
          this.$message({ message: '保存分类成功！', type: 'success' })
        })
      })
    },
    // 关闭添加分类会话框
    closeAddCategory() {
      this.categoryAddVisible = false
      for (const k in this.categoryForm) this.categoryForm[k] = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cont{
  .f-right{ float: right }
  ::v-deep .el-form-item__error{ margin-left: 78px }
  .create-popup{
    ::v-deep .el-form-item__label{ width: 84px!important; }
  }
}
</style>

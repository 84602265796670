import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/fastReply/getPage',
   save: '/event/enterpriseWechat/fastReply/saveFastReply',
   delete: '/event/enterpriseWechat/fastReply/removeFastReply',
   select: '/event/enterpriseWechat/fastReplyCategory/getList',
   categoryPage: '/event/enterpriseWechat/fastReplyCategory/getPage',
   categoryDel: '/event/enterpriseWechat/fastReplyCategory/removeCategory',
   categorySave: '/event/enterpriseWechat/fastReplyCategory/saveCategory'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function saveFastReply(params) {
   return request({
      url: api.save,
      method: 'POST',
      data: params
   })
}

export function delFastReply(params) {
   return request({
      url: api.delete,
      method: 'POST',
      data: params
   })
}

export function getSelect(params = {}) {
   return request({
      url: api.select,
      method: 'POST',
      data: params
   })
}

export function getCategoryPage(query, params = {}) {
   return request({
      url: api.categoryPage + query,
      method: 'POST',
      data: params
   })
}

export function removeCategory(params) {
   return request({
      url: api.categoryDel,
      method: 'POST',
      data: params
   })
}

export function saveCategory(params) {
   return request({
      url: api.categorySave,
      method: 'POST',
      data: params
   })
}

